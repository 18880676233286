import local from './local';
import dev from './dev';
import staging from './staging';
import prod from './prod';
import preProd from './preprod';
import common from './common';

const DEFAULT_ENV = { env: 'prod', lang: 'en' };

export const getEnvFromUrl = (url) => {
  const { hostname, pathname } = new URL(url);
  const host = hostname.split('.');
  const path = pathname.replace(/^\//, '').split('/');
  const xfinity = host.slice(host.length - 2);
  if (xfinity[0] !== 'xfinity' || xfinity[1] !== 'com') {
    return DEFAULT_ENV;
  }
  if (host[0] === 'es-payments') {
    if (host[1] === 'xfinity') {
      return { ...DEFAULT_ENV, lang: 'es' };
    }
    return {
      env: host[1].replace(/^pre-?/, 'pre-'),
      lang: 'es',
    };
  }
  if (host[0] === 'es' && path[0].startsWith('sdcustomer')) {
    const env = path[0].substring(10) || DEFAULT_ENV.env;
    return {
      env: env.replace(/^pre-?/, 'pre-'),
      lang: 'es',
    };
  }
  if ((host[0] !== 'payments' && host[0] !== 'customer') || host[1] === 'xfinity') {
    return DEFAULT_ENV;
  }
  return {
    env: host[1].replace(/^pre-?/, 'pre-'),
    lang: 'en',
  };
};

const envs = {
  local,
  dev,
  staging,
  'pre-prod': preProd,
  prod,
};

const getConfig = () => {
  const { lang, env } = getEnvFromUrl(window.location);
  return { lang, env, ...(envs[env] || common) };
};

export default getConfig;
