import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dateFormat from 'dateformat';
import { PageSection, LoadingCard } from 'adc-ui-components';

import { sendChannelTracking } from '../../actions/channelTracking';
import { XFINITY_ASSISTANCE_PLAN_FAILED } from '../../helpers/channelTracking';
import { joinClass } from '../../helpers/component';
import { kibanaLog } from '../../helpers/logger';
import { flagEnabled } from '../../helpers/featureFlags';

import Card from '../../components/Card';
import AutopayCheckbox from '../../components/AutopayCheckbox';
import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { scrollToInvalid, getErrorFromResponse } from '../../helpers/errors';
import withCmsContent from '../../helpers/withCmsContent';
import paymentMock from '../../helpers/paymentMock';
import PageTitle from '../../components/PageTitle';
import Jump from '../Jump';

import {
  COLLECTIONS_CONFIRMATION,
  COLLECTIONS,
} from '../../helpers/routes';
import { submitPayment } from '../../actions/collections';
import KeyValueGroup from '../../components/KeyValueGroup';
import KeyValue from '../../components/KeyValue';
import { formatCurrency } from '../../helpers/formatText';
import PaymentInstrument from '../../components/PaymentInstrument';
import CtaLink from '../../components/CtaLink';

class PaymentPlanReview extends Component {
  constructor() {
    super();

    this.state = {
      autopay: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const {
      review,
      history,
      showAutoPay,
      bill,
    } = this.props;

    // if no review data go back to payment
    if (!review) {
      const mock = paymentMock(false);
      if (mock) {
        const { form, bill: mockBill } = mock;
        Object.assign(bill, mockBill);
        this.setState({ showAutoPay: true, review: form });
        return;
      }
      history.push(COLLECTIONS);
    }

    // put this in the state because the enable autopay
    // call will complete before payment submit, and makes
    // updates the global store
    this.setState({ showAutoPay });
  }

  onFormSubmit(e) {
    const {
      handleSubmitPayment,
      history,
      // handleSetSubmit,
    } = this.props;
    const { autopay } = this.state;

    e.preventDefault();

    // handleSetSubmit();
    kibanaLog('payment_form_submit', {
      form: 'Collections/Review',
    });

    handleSubmitPayment({ autopay }).then(() => {
      history.push(COLLECTIONS_CONFIRMATION);
    }).catch(this.onError);
  }

  onError(err = {}) {
    const {
      history,
      handleSetError,
      handleSendChannelTracking,
    } = this.props;
    const error = getErrorFromResponse(err);

    scrollToInvalid();
    handleSendChannelTracking({ id: XFINITY_ASSISTANCE_PLAN_FAILED, interactionType: 'BILLING_PAYMENTS' });

    if (error.message) {
      // persist the unmapped error in the state
      // then clear them on resubmit
      history.push(COLLECTIONS);
      throw error;
    }

    handleSetError({ showModal: true });
    history.push(COLLECTIONS);
  }

  render() {
    const {
      autopay,
      loading,
      history,
      review: propsReview,
      submitting,
      content,
      bill,
      pauseConsentId,
      servicePause,
      cartError,
      deviceFingerprintId,
    } = this.props;
    const {
      review: stateReview,
    } = this.state;

    const review = propsReview || stateReview;

    const { autopay: autopayVal, showAutoPay } = this.state;

    const txnDataPaymentEnabled = flagEnabled('txnDataPaymentEnabled');

    if (!review) return null;

    return (
      <PageSection>
        {txnDataPaymentEnabled && !deviceFingerprintId && (
          // This loads CPC's "standalone" component - there is no UI
          <Jump cpcPageType="DeviceFingerPrintId" />
        )}
        <form
          onSubmit={this.onFormSubmit}
          noValidate
        >
          {pauseConsentId && !cartError && servicePause.monthlyCharges && (
            <>
              <PageTitle>{content('services.title')}</PageTitle>

              <div className="payment-section">
                <div className="card-group">
                  <div className="card-group__item">
                    <Card
                      className="card--flex-start"
                      actionRight
                    >
                      <KeyValueGroup className="key-value-group--mobile-table">
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('services.fields.services.label')}
                          value={content(`services.plans.${servicePause.lineOfService}`)}
                        />
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('services.fields.amount.label')}
                          value={`${formatCurrency(servicePause.monthlyCharges)}/mo.`}
                        />
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('services.fields.startDate.label')}
                          value={content('services.fields.startDate.value')}
                        />
                      </KeyValueGroup>
                    </Card>
                  </div>
                  <div className="card-group__item">
                    <Card>
                      <p>
                        {content('services.footer.message')}
                        {content('services.footer.cta') && (
                          <>
                            {' '}
                            <CtaLink {...content('services.footer.cta.*')} />
                          </>
                        )}
                      </p>
                    </Card>
                  </div>
                </div>
              </div>
            </>
          )}
          {pauseConsentId && cartError && (
            <>
              <PageTitle>{content('services.title')}</PageTitle>
              <div className="payment-section">
                <div className="card-group">
                  <div className="card-group__item">
                    <Card
                      className="card--flex-start"
                      severity="warning"
                    >
                      <p>{content('services.error')}</p>
                    </Card>
                  </div>
                </div>
              </div>
            </>
          )}

          <PageTitle>{ review.plan.isP2P ? content('payment.title.review') : content('payment.title.today') }</PageTitle>

          <div className="payment-section">
            <div className="card-group">
              <div className="card-group__item">
                <Card
                  className="card--flex-start"
                  actionRight
                >
                  <KeyValueGroup className="key-value-group--mobile-table">
                    <KeyValue
                      className="key-value--inline-at-768"
                      label={content('payment.fields.amount')}
                      value={formatCurrency(review.amount)}
                    />
                    <KeyValue
                      className="key-value--inline-at-768"
                      label={content('payment.fields.date')}
                      value={dateFormat(review.date, 'dddd, mmmm d, yyyy')}
                    />
                    <KeyValue
                      className="key-value--inline-at-768"
                      label={content('payment.fields.method')}
                      value={<PaymentInstrument instrument={review.instrument} hideBadges />}
                    />
                    <KeyValue
                      className="key-value--inline-at-768"
                      label={content('payment.fields.email')}
                      value={review.email}
                    />
                  </KeyValueGroup>
                </Card>
              </div>
              <div className="card-group__item">
                <Card>
                  <p>{content('payment.footer')}</p>
                </Card>
              </div>
            </div>
            {!review.isToday && review.plan.isP2P && (
              <Card severity="warning">
                { bill.summary.softDisconnected ? content('payment.p2pWarningSoftDisco') : content('payment.p2pWarning') }
              </Card>
            )}
          </div>

          {review.plan.isLtip && (
            <>
              <PageTitle>{content('ltip.title')}</PageTitle>
              <div className="payment-section">
                <div className="card-group">
                  <div className="card-group__item">
                    <Card
                      className="card--flex-start"
                      actionRight
                    >
                      <KeyValueGroup className="key-value-group--mobile-table">
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('ltip.fields.amount')}
                          value={(
                            <>
                              {formatCurrency(review.plan.installment)}
                              {' ('}
                              {review.plan.noOfPayments}
                              {' '}
                              {review.plan.freqUnit}
                              {')'}
                            </>
                          )}
                        />
                        <KeyValue
                          className="key-value--inline-at-768"
                          label={content('ltip.fields.startDate.label')}
                          value={content('ltip.fields.startDate.value')}
                        />
                      </KeyValueGroup>
                    </Card>
                  </div>
                  <div className="card-group__item">
                    <Card>
                      <p>{content('ltip.footer')}</p>
                    </Card>
                  </div>
                </div>
                <Card severity="warning">{content('ltip.noChangesWarning')}</Card>
              </div>
            </>
          )}

          {loading && <LoadingCard />}

          {!loading && showAutoPay && (
            <AutopayCheckbox
              autopayVal={autopayVal}
              handleSetAutopayState={() => this.setState({ autopay: !autopayVal })}
              autopay={autopay}
              review={review}
            />
          )}

          <div className="action action--right pt6">
            <div className="action__item">
              <button
                className={joinClass('button button--primary', submitting && 'is-loading')}
                disabled={submitting}
                type="submit"
              >
                {content('form.submit')}
              </button>
            </div>
            <div className="action__item">
              <button className="button" type="button" onClick={() => history.goBack()}>
                {content('form.back')}
              </button>
            </div>
          </div>
        </form>
      </PageSection>
    );
  }
}

const mapStateToProps = ({
  auth: {
    pauseConsentId,
  } = {},
  autopay: {
    autopay,
    loading: autopayLoading,
  } = {},
  bill: {
    bill,
    loading: billLoading,
    bill: {
      summary: {
        autoPayEnabled,
      } = {},
    } = {},
  },
  payment: {
    submitting: paymentSubmitting,
  },
  collections: {
    review,
    collections: {
      consentCart: {
        consentGroups: [{
          consentItems: [{
            chargeDetails: [{
              monthlyCharges,
            } = {}] = [],
            lineOfService,
          } = {}] = [],
        } = {}] = [],
      } = {},
    } = {},
    submitting: paymentPlanSubmitting,
    error: cartError,
  },
}) => ({
  autopay,
  bill,
  review,
  loading: autopayLoading || billLoading,
  showAutoPay: !autoPayEnabled && !review?.walletId,
  submitting: paymentPlanSubmitting || paymentSubmitting,
  pauseConsentId,
  servicePause: {
    monthlyCharges,
    lineOfService,
  },
  cartError,
  // `deviceFingerprintId` will be in sessionStorage if the user went through CPC's tokenization
  // of a new payment method.
  // If it's not there then we will load CPC's standalone template to get it.
  deviceFingerprintId: sessionStorage.getItem('maw_deviceFingerprintId'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSubmitPayment: submitPayment,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export default withCmsContent('content', {
  'ltip-payment-plan': 'Review',
  'collections-2': 'Review',
})(connect(mapStateToProps, mapDispatchToProps)(PaymentPlanReview));
