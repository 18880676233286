import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { bindActionCreators } from 'redux';

import { submitAutopaySignup } from '../../actions/bill';

import { joinClass } from '../../helpers/component';
import { getDefaultInstrumentValue } from '../../helpers/paymentInstrument';
import { sendImpression } from '../../actions/adobe';
import { getPreferredEmail } from '../../helpers/account';

import PageTitle from '../../components/PageTitle';
import ErrorMessage from '../../components/ErrorMessage';
import Card from '../../components/Card';
import PaymentMethods from '../../components/form-fields/PaymentMethods';
import AutopayCheckbox from '../../components/form-fields/AutopayCheckbox';
import BooleanField from '../../components/form-elements/BooleanField';
import Toggle from '../../components/form-fields/Toggle';
import getConfig from '../../config';

import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { kibanaLog } from '../../helpers/logger';
import { flagEnabled } from '../../helpers/featureFlags';

import {
  TERMS_ECOBILL,
} from '../../helpers/routes';

const {
  paymentSetup: { defaultContinue, redirectDelay = 0 },
} = getConfig();

class AutopaySignupForm extends Component {
  constructor() {
    super();
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const {
      handleSendImpression,
      showEcobill,
      accountError,
    } = this.props;

    if (accountError) {
      kibanaLog('payment-setup', {
        type: 'redirect',
        message: 'from account error',
        target: defaultContinue,
      });
      setTimeout(() => {
        window.location = defaultContinue;
      }, redirectDelay);
    }

    let componentName = 'paperless_sign_up_module_not_displayed';

    if (showEcobill) {
      componentName = 'paperless_sign_up_module_displayed';
    }

    handleSendImpression([{
      placementName: 'myaccount|billing|consent|autopay',
      componentName,
    }]);
  }

  onFormSubmit(values) {
    const {
      handleSubmitAutopaySignup,
      continueUrl,
      crsId,
    } = this.props;

    handleSubmitAutopaySignup(values)
      .then(() => {
        kibanaLog({
          type: 'redirect',
          message: 'after submit',
          target: continueUrl || defaultContinue,
          crsId,
        });
        setTimeout(() => {
          window.location = continueUrl || defaultContinue;
        }, redirectDelay);
      })
      .catch((error) => {
        if (error.status === 400) {
          this.setState(state => ({
            ...state,
            error: {
              ...error,
              message: 'Payment processor refused to create instrument',
            },
          }));
          return;
        }
        if (error.status === 500 && error.data && error.data.error) {
          const {
            data: {
              error: {
                errorDetails: [{ text } = {}] = [],
                errorMessage = '',
              } = {},
            } = {},
          } = error;

          if (text || errorMessage) {
            this.setState(state => ({
              ...state,
              error: {
                ...error,
                message: text || errorMessage,
              },
            }));
            return;
          }
        }
        this.setState(state => ({
          ...state,
          error,
        }));
      });
  }

  render() {
    const {
      autopay,
      bill,
      instruments,
      applePay,
      submitting,
      email,
      showEcobill,
      forceBillingAddress,
      continueUrl,
      crsId,
      initialValues,
    } = this.props;

    const {
      error,
    } = this.state;

    const ecobillTermsLink = `${TERMS_ECOBILL}${crsId ? `?crsId=${crsId}` : ''}`;
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onFormSubmit}
      >
        {({
          values, setFieldValue,
        }) => (
          <Form noValidate>
            {error && (
            <ErrorMessage errorMessage={
              error.message
              || (error.data && error.data.message)
              || 'Something went wrong.  Please try again later.'
            }
            />
            )}

            <div className="payment-section">
              <div className="hgroup">
                <PageTitle>Let&apos;s set up your preferred payment method</PageTitle>
                <p>A preferred payment method makes it easier to pay your bill each month.</p>
              </div>

              <PaymentMethods
                autopay={autopay}
                applePay={applePay}
                instruments={instruments}
                bill={bill}
                values={values}
                required={(!values.ecobill) || values.autopayEnabled}
                nestFields
                forceBillingAddress={forceBillingAddress}
                setFieldValue={setFieldValue}
              />
            </div>

            <div className="payment-section">
              <div className="hgroup">
                <h2>Enroll in automatic payments</h2>
                <p>Simplify your life! Never miss a payment.</p>
              </div>

              <AutopayCheckbox showContract showStored crsId={crsId} />
            </div>

            {showEcobill && (
              <div className="payment-section">
                <div className="hgroup">
                  <h2>Save a tree and go paperless</h2>
                  <p>We will send you a link to verify your email address.</p>
                </div>

                <div className="card-group">
                  <div className="card-group__item">
                    <Card>
                      <Toggle
                        label="Turn on paperless billing"
                        className="form-toggle--flex"
                        labelClass="body3 visuallyhidden"
                        inputId="ecobill"
                        toggleContent={(
                          <>
                            Turn on paperless billing and we’ll email you at
                            {' '}
                            <strong>{email}</strong>
                            {' '}
                            when your bill is ready.
                            {' '}
                            You can change these preferences in your account anytime.
                          </>
                        )}
                      >
                        <Field
                          id="ecobill"
                          name="ecobill"
                          type="checkbox"
                          checked={values.ecobill}
                          component={BooleanField}
                        />
                      </Toggle>
                    </Card>
                  </div>
                  <div className="card-group__item">
                    <Card>
                      <p>
                        {'By enrolling, I agree to the '}
                        <a href={ecobillTermsLink} target="_blank" rel="noopener noreferrer">Terms and Conditions for Paperless Billing.</a>
                      </p>
                    </Card>
                  </div>
                </div>
              </div>
            )}

            <div className="action action--right pt6">
              <div className="action__item">
                <button
                  type="submit"
                  className={joinClass('button button--primary', submitting && 'is-loading')}
                  disabled={submitting}
                >
                  Continue
                </button>
              </div>
              <div className="action__item">
                <a className="button" href={continueUrl || defaultContinue}>I&apos;ll Do This Later</a>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    autopay,
    bill: {
      bill,
      bill: {
        ecoBillDeliveryMethod: {
          deliveryMethod = null,
        } = {},
      } = {},
      billStateCheck: {
        autopayEnabled = false,
      } = {},
      submitting,
    },
    applePay: { applePay },
    instruments: { instruments },
    auth: {
      macaroon,
      fallbackUrl,
      crs: {
        continueUrl = defaultContinue,
      } = {},
      crsId,
    },
    account: { account, account: { serviceAddress } = {} } = {},
  } = state;

  const email = getPreferredEmail(account, macaroon);
  const showEcobill = email && (
    !(deliveryMethod && deliveryMethod === 'ELECTRONIC')
    && (flagEnabled('consent.ecobill') || !crsId)
  );

  return {
    autopay,
    instruments,
    bill,
    continueUrl: fallbackUrl || continueUrl,
    crsId,
    applePay: applePay && !crsId,
    submitting,
    email,
    showEcobill,
    forceBillingAddress: !serviceAddress,
    initialValues: {
      autopayEnabled,
      billingAddressCheckbox: false,
      account: 'Checking',
      paymentMethodOption: getDefaultInstrumentValue(instruments, autopay.autopay),
    },
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSubmitAutopaySignup: submitAutopaySignup,
  handleSendImpression: sendImpression,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AutopaySignupForm);
