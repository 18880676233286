import { isAutoPayInstrument } from './instruments';

export const cleanInstrumentNumber = number => number.toString().replace(/\s+/g, '').replace(/[^0-9]+/g, '');
export const last4 = (number) => {
  const clean = cleanInstrumentNumber(number);
  return clean.substring(clean.length - 4);
};

export const getPaymentCardType = (cardNumber) => {
  const cleanNumber = cleanInstrumentNumber(cardNumber);

  // ***********************************************************************
  // NOTE: Other ranges exist, but these are what are currently supported by
  // the payment service as of January 2017.
  // ***********************************************************************

  // American Express card numbers start with 34 or 37 and have 15 digits.
  if (/^3[47][0-9]{13}$/.test(cleanNumber)) {
    return 'American Express';
  }

  // Discover card numbers begin with the following, and have 16-19 digits.
  // 6011, 622126-622925, 644-649, 65
  const disc = parseInt(cleanNumber.substring(0, 6), 10);
  if (
    (
      (disc >= 601100 && disc <= 601199)
      || (disc >= 622126 && disc <= 622925)
      || (disc >= 644000 && disc <= 649999)
      || (disc >= 650000 && disc <= 659999)
    )
    && (cleanNumber.length >= 16 && cleanNumber.length <= 19)
  ) {
    return 'Discover';
  }

  // MasterCard numbers either start with the number 36 or with the numbers 51 through 55 or
  // with the numbers 2221 through 2720. All have 16 digits.
  if (/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720|36[0-9]{2})[0-9]{12}$/.test(cleanNumber)) {
    return 'MasterCard';
  }

  // All Visa card numbers start with a 4. New cards have 16 digits. Old cards have 13.
  // For some ranges, we can assert a length of 16.
  if (
    (/^4026|^417500|^4405|^4508|^4844|^4913|^4917/.test(cleanNumber) && cleanNumber.length === 16)
    || (/^4/.test(cleanNumber) && cleanNumber.length >= 13 && cleanNumber.length <= 19)
  ) {
    return 'Visa';
  }

  return 'CardPayment';
};

// Mostly the same logic as above, but without card length enforcement
export const getPartialPaymentCardType = (cardNumber) => {
  const cleanNumber = cleanInstrumentNumber(cardNumber);
  if (/^3[47]/.test(cleanNumber)) {
    return 'American Express';
  }
  // Discover card numbers begin with the following, and have 16-19 digits.
  // 6011, 622126-622925, 644-649, 65
  const disc = parseInt(cleanNumber.substring(0, 6), 10);
  if (
    (disc >= 601100 && disc <= 601199)
    || (disc >= 622126 && disc <= 622925)
    || (disc >= 644000 && disc <= 649999)
    || (disc >= 650000 && disc <= 659999)
  ) {
    return 'Discover';
  }
  if (/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720|36[0-9]{2})/.test(cleanNumber)) {
    return 'MasterCard';
  }
  if (/^4/.test(cleanNumber)) {
    return 'Visa';
  }
  return undefined;
};
const patterns = {
  Visa: [4, 4, 4, 4, 3],
  VisaKnown: [4, 4, 4, 4],
  'American Express': [4, 6, 5],
  MasterCard: [4, 4, 4, 4],
  Discover: [4, 4, 4, 4, 3],
};

const splitPattern = (number, pattern) => {
  const broken = pattern.reduce((p, entry) => {
    const parts = p;
    if (parts.position >= number.length) {
      return parts;
    }
    parts.value.push(number.substring(parts.position, entry + parts.position));
    parts.position += entry;
    return parts;
  }, { position: 0, value: [] });
  return broken.value.join(' ');
};

export const normalizeCard = (cardNumber) => {
  const cleanNumber = cleanInstrumentNumber(cardNumber);
  let type;
  if (/^4026|^417500|^4405|^4508|^4844|^4913|^4917/.test(cleanNumber)) {
    type = 'VisaKnown';
  } else {
    type = getPartialPaymentCardType(cleanNumber);
  }
  if (type === undefined) {
    return cardNumber.replace(/[^0-9\s-]+/g, '').substring(0, 19);
  }
  return splitPattern(cleanNumber, patterns[type]);
};

export const luhnChecksum = (cardNumber) => {
  const cleanValue = cleanInstrumentNumber(cardNumber);
  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;
  let n;
  let cDigit;

  for (n = cleanValue.length - 1; n >= 0; n -= 1) {
    cDigit = cleanValue.charAt(n);
    nDigit = parseInt(cDigit, 10);
    if (bEven) {
      nDigit *= 2;
      if (nDigit > 9) {
        nDigit -= 9;
      }
    }
    nCheck += nDigit;
    bEven = !bEven;
  }

  return (nCheck % 10) === 0;
};

export const getDefaultInstrumentValue = (instrumentOptions, autopay = false) => {
  const { instruments, bankblockStatus } = instrumentOptions;
  const selectedInstruments = checkPaymentMethod(instrumentOptions);
  const acceptablePaymentMethod = checkPaymentOption(bankblockStatus);
  return (
    instruments.find(ins => isAutoPayInstrument(ins, autopay))
    || selectedInstruments[0] || {}
  ).token || acceptablePaymentMethod;
};

const checkPaymentMethod = (instrumentOptions) => {
  const { instruments, cardblockStatus, bankblockStatus } = instrumentOptions;
  return instruments.sort((a, b) => {
    if (b.type === 'Bank') {
      return 1;
    } if (a.type === 'Bank') {
      return -1;
    }
    return 0;
  }).filter(instrument => !((instrument.type === 'PaymentCard' && cardblockStatus) || (instrument.type === 'Bank' && bankblockStatus)));
};

const checkPaymentOption = (bankblockStatus) => {
  if (bankblockStatus) {
    return 'PaymentCard';
  }
  return 'Bank';
};
