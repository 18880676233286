import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash.get';

import { getCMSPackage } from '../actions/cms';


const identifierRx = /%([a-z_][a-z0-9_]*)%/gi;

const addKey = (prefix, piece) => {
  if (piece && typeof piece === 'object') {
    if (Array.isArray(piece)) {
      return piece.map((p, i) => addKey(`${prefix}-${i}`, p));
    }
    return React.cloneElement(piece, { key: prefix });
  }
  return piece;
};

const subst = (s, props) => {
  let comps = false;
  const values = s.split(identifierRx).map((part, index) => {
    if ((index % 2) === 0) return part;
    if (!(part in props)) {
      // eslint-disable-next-line no-console
      console.warn([
        `Unknown prop in CMS template: "%${part}%"`,
        `Available props: ${Object.keys(props)}`,
      ].join('\n  '));
      return '';
    }
    if (props[part] && typeof props[part] === 'object') {
      comps = true;
      return addKey(`${part}-${index}`, props[part]);
    }
    return props[part];
  });
  if (comps) return values;
  return values.join('');
};

const walkSubst = (path, obj, repl) => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'string') {
      ret[key] = subst(value, repl);
      return;
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      ret[key] = walkSubst(path, value, repl);
      return;
    }
    // Should be impossible
    // eslint-disable-next-line no-console
    console.warn([
      'Malformed CMS data:',
      `  Path: ${path}`,
      `  Data: \n  ${JSON.stringify(obj, null, 2).split('\n').join('\n  ')}`,
    ].join('\n'));
  });
  return ret;
};

const mkLookup = (contentRoot, basePath, baseVars = {}) => {
  const root = basePath ? get(contentRoot, basePath) : contentRoot;
  return (path, vars = {}) => {
    let p = path;
    let all = false;
    if (p.endsWith('.*')) {
      p = p.replace(/\.\*$/, '');
      all = true;
    }
    const value = p === '' ? root : get(root, p);
    if (!value) return undefined;
    if (typeof value === 'string') {
      return subst(value, { ...baseVars, ...vars });
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      if (!all) {
        return mkLookup(root, p, { ...baseVars, ...vars });
      }
      return walkSubst(path, value, { ...baseVars, ...vars });
    }
    // Should be impossible.
    // eslint-disable-next-line no-console
    console.warn([
      'Malformed CMS data:',
      `  Path: ${path}`,
      `  Data: \n  ${JSON.stringify(root, null, 2).split('\n').join('\n  ')}`,
    ].join('\n'));
    return undefined;
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleGetCmsPackage: getCMSPackage,
}, dispatch);

/**
 * This callback is passed into a component by withCmsContent
 * @callback ContentLookup
 * @param {String} path - Path to look up in the content table.
 *  If the target of this path is a string, it returns the substituted string or
 *    Array<String|JSX>, as appropriate.
 *  It it's an object, it returns a ContentLookup function rooted there.
 *  If the path ends with `.*` and the path before that refers to an object,
 *    it returns a fully substituted copy of that object.
 * @param {Object} props - properties for substitutions
 * @return {String|Array<String|JSX>|ContentLookup|Object} React renderable, lookup
 *  function, or object of renderables.
 */

/**
 * High-order component to fetch CMS package(s) content and pass it into a component
 * Usage:
 *  WrappedComponent = withCmsContent(contentName, packageSpec)(Component);
 *
 *  The component will have access to the CMS content as a lookup function at the name given as
 *   `contentName` (@see {ContentLookup}).  It will also have access to the CMS content's loaded
 *   state as `cmsLoading`.
 *
 * Example CMS response for `package-name-1`:
 *  ```
 *  {
 *    layout: [
 *      {
 *        "path": {
 *          "into": {
 *            "mousePackage": {
 *              "title": "Counter",
 *              "counterMsg": {
 *                "youveClicked": "You have clicked %count% times",
 *                "classes": "mouse-cta"
 *              }
 *            },
 *            "touchPackage": {
 *              "title": "Counter",
 *              "counterMsg": {
 *                "youveClicked": "You have tapped %count% times",
 *                "classes": "touch-cta"
 *              }
 *            }
 *          }
 *        }
 *      }
 *    ]
 *  }
 *  ```
 *
 * Example:
 *  ```
 *  const MyComponentWithContent = withCmsContent('content', {
 *    'package-name-1': {
 *      $: 'path.into',
 *    },
 *  })((
 *    {
 *      cmsLoading, // true / false for the set
 *      content, // content lookup function
 *    },
 *    isTouch,
 *    count,
 *  }) => {
 *    // Indexing to an object returns a content function
 *    const msgs = content(isTouch ? 'touchPackage' : 'mousePackage');
 *    // Indexing to a string returns the string
 *    const title = msgs('title');
 *    // Strings may have named substitutions
 *    const clicked = msgs('counterMsg.youveClicked', { count });
 *    return (
 *      <div>
 *        <h1>{title}</h1>
 *        <h2 className={msgs('counterMsg.classes')}>
 *          {msgs('counterMsg.youveClicked', { count })}
 *        </h2>
 *      </div>
 *    );
 *  });
 *  ```
 *
 * @param {String} contentName - The property name that will be used for the content lookup function
 * @param {Object} packageSpec - The specification for pulling in content, in the following form:
 *     ```
 *     {
 *       'layout-package-name': {
 *         propName: 'path.into.package'
 *         // ...
 *       },
 *       'another-layout-package': 'path.into.package',
 *       // ...
 *     }
 *     ```
 *     `propName` represents where the package's data should be rooted in the lookup function;
 *     if this is simply `$`, or if the value of the package is a string, the path from the
 *     package will become the lookup root.
 *
 * @return {ContentLookup} A content lookup function
 */

const withCmsContent = (contentName, cmsPackages) => (Component) => {
  const mapStateToProps = ({
    cms: { packages },
  }) => ({
    cms: Object.keys(cmsPackages).reduce((cms, pkgName) => {
      let table = cmsPackages[pkgName];
      if (typeof table === 'string') {
        table = { $: table };
      }
      return {
        loaded: cms.loaded && !!(packages[pkgName] && packages[pkgName].content),
        cmsLoading: cms.cmsLoading || (packages[pkgName] && packages[pkgName].loading),
        content: Object.keys(table).reduce((locals, localName) => ({
          ...locals,
          ...(
            (localName === '' || localName === '$')
              ? get(packages, `${pkgName}.content.${table[localName]}`)
              : { [localName]: get(packages, `${pkgName}.content.${table[localName]}`) }
          ),
        }), cms.content),
      };
    }, { cmsLoading: false, loaded: true, content: {} }),
  });

  const connector = connect(mapStateToProps, mapDispatchToProps);

  return connector(({
    handleGetCmsPackage,
    cms: { cmsLoading, loaded, content },
    ...props
  }) => {
    useEffect(() => {
      if (!loaded && !cmsLoading) {
        Object.keys(cmsPackages).forEach((pkgName) => {
          handleGetCmsPackage(pkgName);
        });
      }
    }, [loaded, cmsLoading, handleGetCmsPackage]);

    return (
      <Component
        {...props}
        {...(contentName && { [contentName]: mkLookup(content) })}
        cmsLoading={cmsLoading}
      />
    );
  });
};

export default withCmsContent;
