import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Plus from '../../components/svgs/Plus';
import Card from '../../components/Card';
import PaymentInstrument from '../../components/PaymentInstrument';
import { setSuccess } from '../../actions/userMessages';
import GoBack from '../../components/GoBack';
import PageTitle from '../../components/PageTitle';

import { sortAutoPayFirst } from '../../helpers/instruments';

import {
  METHOD_NEW,
  METHOD_EDIT,
  resolveRoute,
} from '../../helpers/routes';

const ListMethods = ({
  autopay,
  instruments,
  onRemoveClick,
  handleSetSuccess,
}) => {
  // Success message will be visible on this component, but shouldn't persist to other components.
  useEffect(() => () => {
    handleSetSuccess && handleSetSuccess(undefined);
    // Only ever do this on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>Saved Payment Methods</PageTitle>

      <div className="card-group mb12">
        {sortAutoPayFirst(instruments, autopay).map((instrument) => {
          const action = (
            <div className="link-group">
              {instrument.type === 'PaymentCard' && (
                <div className="link-group__item mb0">
                  <Link to={resolveRoute(METHOD_EDIT, { token: instrument.token })}>Edit</Link>
                </div>
              )}
              <div className="link-group__item mb0">
                <button
                  className="button button--text"
                  type="button"
                  onClick={() => onRemoveClick(instrument)}
                >
                  Remove
                </button>
              </div>
            </div>
          );

          return (
            <div className="card-group__item" key={instrument.token}>
              <Card className="card--action-right-at-768" action={action}>
                <PaymentInstrument autopay={autopay} instrument={instrument} />
              </Card>
            </div>
          );
        })}
      </div>

      <Link to={METHOD_NEW} className="card card--circle-plus mb24">
        <div className="card__image">
          <span className="svg-icon--40 color--blue-sky svg-icon">
            <Plus />
          </span>
        </div>
        <div className="card__content_12pxleft">
          <span className="link">Add Payment Method</span>
        </div>
      </Link>

      <div className="action action--right-at-768">
        <GoBack />
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  handleSetSuccess: bindActionCreators(setSuccess, dispatch),
});

export default connect(null, mapDispatchToProps)(ListMethods);
