export const RECURRING_PAYMENT = 'RecurringPayment';
export const ONE_TIME_PAYMENT = 'OneTimePayment';

export const isAutoPayInstrument = (instrument = {}, autopay = false) => (
  (instrument.paymentMode === RECURRING_PAYMENT)
  || (
    autopay?.instrument?.type === 'ApplePay'
    && instrument.type === 'ApplePay'
  )
);

// TODO: This probably won't work on review.
const commonFields = {
  ApplePay: [],
  Bank: ['bankAccountType', ['bankAccountNumber', 'bankAccountLast4Digits']],
  PaymentCard: ['cardType', ['last4DigitsCardNumber', 'cardLast4Digits'], 'expirationDate'],
};

export const getAreInstrumentsEqual = (a, b) => {
  if (!a || !b || a.type !== b.type || !commonFields[a.type]) return false;
  if (!commonFields[a.type].length) return true;
  return !commonFields[a.type].some((key) => {
    if (Array.isArray(key)) {
      return !key.some(k1 => key.some(k2 => (
        k1 in a && k2 in b && a[k1] === b[k2]
      )));
    }
    return a[key] !== b[key];
  });
};

export const isBlockedInstrument = (instruments, instrument) => (
  (instrument.type === 'PaymentCard' && instruments.cardblockStatus)
  || (instrument.type === 'Bank' && instruments.bankblockStatus)
);

export const canUseInstrument = (options, instrument) => !options || !!(options.find(option => (
  (instrument.bankAccountType && instrument.bankAccountType === option.bankAccountType)
  || (instrument.cardType && instrument.cardType === option.cardType)
)));

export const sortAutoPayFirst = (instruments = [], autopay = {}) => {
  const sortedInstruments = [];
  instruments.forEach((instrument) => {
    if (isAutoPayInstrument(instrument, autopay)) {
      sortedInstruments.unshift(instrument);
    } else {
      sortedInstruments.push(instrument);
    }
  });
  return sortedInstruments;
};

export const isNewMethod = paymentMethodOption => (
  paymentMethodOption === 'Bank' || paymentMethodOption === 'PaymentCard'
);

export const getHasAccountAutopayToken = (
  { instrument: { autopayUserGuid } = {} } = {},
  { fullAccountNumber } = {},
) => autopayUserGuid === `BA${fullAccountNumber}`;
