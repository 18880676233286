import { generateUUID } from './uids';
// TODO: most of this file can be exported to a common lib

// MW endpoint for channel tracking.
export const CHANNEL_TRACKING_URL = '/apis/channel-tracking';

// impl agnostic for common lib
// eslint-disable-next-line no-undef
const assign = (typeof angular === 'undefined') ? Object.assign : angular.extend;

/**
 * Sends a channel tracking payload.
 * @param {Object} options
 * @param {string} options.id Channel tracking action ID
 * @param {Error} [options.error] Error object if this action is an error
 * @param {Object} options.macaroon The user's current macaroon
 * @param {Array<Object>} options.macaroon.accounts The user's account list
 * @param {string} options.macaroon.mainAccountNumber The user's current account number
 * @param {string} options.macaroon.accounts[].accountNumber The account's account number
 * @param {string} options.macaroon.accounts[].guid The account's customer GUID
 * @return {Object} A tracking payload for 5Standards
 */
export const buildChannelPayload = ({
  id,
  interactionType = 'UNKNOWN',
  error,
  account: { fullAccountNumber, accountGuid },
  isHarness = false,
  metadata = {},
}) => {
  const uuid = generateUUID();
  return {
    LogType: 'DSS_CustomerInteraction',
    TransactionId: uuid,
    eventTimestamp: String(Date.now()),
    session: {
      channel: isHarness ? 'MY_ACCOUNT_APP' : 'MY_ACCOUNT_WEB',
      customer: {
        accountNumber: fullAccountNumber,
        custGuid: accountGuid,
      },
    },
    action: {
      id,
      intent: '',
      utterance: null,
      status: (error || id.endsWith('FAILED')) ? 'FAILED' : 'SUCCESS',
      failureReason: error ? error.message : null,
      metadata: assign({ trackingId: uuid }, metadata),
    },
    journeyId: null,
    interactionId: null,
    interactionType,
    contextCode: null,
    disposition: null,
  };
};

export const PMT_FLOW_INITIATED = 'PMT_FLOW_INITIATED';
export const PAYMENT_FAILED = 'ONE_TIME_PMT_FAILED';
export const P2P_FAILED = 'P2P_FAILED';
export const PAYMENT_IMM_COMPLETE = 'IMM_ONE_TIME_PMT_COMPLETED';
export const PAYMENT_SCH_COMPLETE = 'SCH_ONE_TIME_PMT_COMPLETED';
export const P2P_COMPLETED = 'P2P_COMPLETED';

export const ECOBILL_ENROLL_FAILED = 'ECO_ENROLL_FAILED';
export const ECOBILL_UNENROLL_FAILED = 'ECO_UNENROLL_FAILED';
export const ECOBILL_ENROLL_COMPLETE = 'ECO_ENROLL_COMPLETE';
export const ECOBILL_UNENROLL_COMPLETE = 'ECO_UNENROLL_COMPLETE';

export const VIEW_BILL_DETAILS = 'VIEW_BILL_DETAILS';

export const AUTOPAY_ENROLL_COMPLETE = 'AUTOPAY_ENROLL_COMPLETE';
export const AUTOPAY_UNENROLL_COMPLETE = 'AUTOPAY_UNENROLL_COMPLETE';
export const AUTOPAY_ENROLL_FAILED = 'PAYMENT_AUTO_ENROLL_FAILED';
export const AUTOPAY_UNENROLL_FAILED = 'PAYMENT_AUTO_UNENROLL_FAILED';


export const XFINITY_ASSISTANCE_PLAN_INITIATED = 'XFINITY_ASSISTANCE_PLAN_INITIATED';
export const XFINITY_ASSISTANCE_PLAN_COMPLETED = 'XFINITY_ASSISTANCE_PLAN_COMPLETED';
export const XFINITY_ASSISTANCE_PLAN_FAILED = 'XFINITY_ASSISTANCE_PLAN_FAILED';
