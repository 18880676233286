import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
  IconCheckGreen,
  HarnessExitButton,
  LoadingCard,
  PageSection,
} from 'adc-ui-components';
import get from 'lodash.get';
import dateformat from 'dateformat';

import { sendChannelTracking } from '../../../actions/channelTracking';
import { P2P_COMPLETED, PAYMENT_IMM_COMPLETE, PAYMENT_SCH_COMPLETE } from '../../../helpers/channelTracking';

import AutopaySummary from '../../../components/AutopaySummary';
import Card from '../../../components/Card';
import PaymentSummary from '../../../components/PaymentSummary';
import P2PConfirmTitle from '../../../components/p2p/P2PConfirmTitle';
import P2PConfirmSubtitle from '../../../components/p2p/P2PConfirmSubtitle';
import P2PConfirmNote from '../../../components/p2p/P2PConfirmNote';
import PageTitle from '../../../components/PageTitle';
import XfinityAssistantBanner from '../../../components/XfinityAssistantBanner';

import { getIsDisconnected } from '../../../helpers/account';
import { getIsToday } from '../../../helpers/date';
import { formatCurrency } from '../../../helpers/formatText';
import { getIsXapExit, getIsXapCurrent, getIsXapExitInProgress } from '../../../helpers/xap';
import paymentMock from '../../../helpers/paymentMock';
import { NEW_PAYMENT, AUTOPAY_EDIT } from '../../../helpers/routes';
import { XA_XAP_EXITED_INTENT } from '../../../helpers/intents';

import getConfig from '../../../config';

import ConfirmTitle from './ConfirmTitle';
import ConfirmSubtitle from './ConfirmSubtitle';
import Promotions from '../Promotions';
import MakeAnotherPaymentButton from './MakeAnotherPaymentButton';
import AccountInfoPushdown from '../AccountInfoPushdown';

const { homeDomain } = getConfig();

const getActionId = ({ bill, confirm }) => {
  if (bill.bill?.promiseToPay?.customerEligibilityChecked?.eligibleForPromiseToPay) {
    return P2P_COMPLETED;
  }
  if (!confirm.date || getIsToday(dateformat(confirm.date, 'yyyy-mm-dd'))) {
    return PAYMENT_IMM_COMPLETE;
  }
  return PAYMENT_SCH_COMPLETE;
};

export class PaymentConfirm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const {
      history,
      confirm,
      bill,
      handleSendChannelTracking,
    } = this.props;
    if (!confirm) {
      const mock = paymentMock(true);
      if (mock) {
        const { bill: mockBill, form } = mock;
        Object.assign(bill, { bill: mockBill });
        this.setState({ confirm: form });
      } else {
        history.push(NEW_PAYMENT);
        return;
      }
    }
    this.setState({ confirm });
    const actionId = getActionId({ bill, confirm });
    handleSendChannelTracking({ id: actionId, interactionType: 'BILLING_PAYMENTS' });
  }

  render() {
    const {
      accountNumber,
      latefeecredit,
      bill,
      autopay,
      isHarness,
      autopaySignupSuccess,
      enrollSuccess,
      showFlexPromo,
      peacockPromo,
      account,
      showAvoidLateFees,
      showXfinityAssistant,
      showP2PNote,
      selectedAccountAlias,
      serviceAddress,
    } = this.props;

    const { confirm } = this.state;

    if (!confirm) {
      return null;
    }

    const { balanceDue } = (get(bill, 'bill.summary') || {});
    const futureBalance = getIsToday(confirm.date)
      ? balanceDue
      : Math.max(0, balanceDue - confirm.amount);

    const autopaySuccessful = confirm.autopay && autopaySignupSuccess;
    const autopayUnsuccessful = confirm.autopay && !autopaySignupSuccess;
    const p2pConfirmNote = showP2PNote && P2PConfirmNote({ bill: bill.bill, confirm });
    const paymentSectionLoading = bill.loading || (confirm.autopay && autopay.loading);
    const titleProps = {
      bill: bill.bill,
      confirm,
      autopay,
      account,
      showAvoidLateFees,
    };
    return (
      <>
        <AccountInfoPushdown
          accountNumber={accountNumber}
          selectedAccountAlias={selectedAccountAlias}
          serviceAddress={serviceAddress}
        />
        <PageSection className="page-section--banner">
          <div className="hgroup mb24">
            <IconCheckGreen className="mb6 svg-icon--42" />
            <PageTitle>
              <P2PConfirmTitle {...titleProps}>
                <ConfirmTitle {...titleProps} />
              </P2PConfirmTitle>
            </PageTitle>
            <p className="body2">
              <P2PConfirmSubtitle {...titleProps}>
                <ConfirmSubtitle {...titleProps} />
              </P2PConfirmSubtitle>
            </p>
          </div>

          <div className="action action--right-centered no-print">
            <MakeAnotherPaymentButton />

            <div className="action__item">
              {!isHarness && <a href={`https://${homeDomain}/billing/services`} className={`button ${futureBalance <= 0 && 'button--primary'}`}>Done</a>}
              {isHarness && <HarnessExitButton className={futureBalance <= 0 && 'button--primary'} intent="xfinitymyaccount://?page=tabaccount&purgeCache=true">Done</HarnessExitButton>}
            </div>
          </div>

          <div className="text-left">
            {(confirm.autopay || p2pConfirmNote) && (
              <div className="payment-section">
                {paymentSectionLoading && <LoadingCard />}
                {!paymentSectionLoading && (
                  <>
                    {autopaySuccessful && (
                      <>
                        <h2>Automatic Payments are On</h2>
                        <AutopaySummary
                          bill={bill.bill}
                          instrument={confirm.instrument}
                          showEdit={!isHarness}
                        />
                      </>
                    )}
                    {autopayUnsuccessful && (
                      <Card severity="danger">
                        <p>
                          {'Unfortunately, we couldn\'t set up automatic payments. Please try again.'}
                          <br />
                          {!isHarness && <Link to={AUTOPAY_EDIT}>Set Up Automatic Payments</Link>}
                        </p>
                      </Card>
                    )}
                    {p2pConfirmNote}
                  </>
                )}
              </div>
            )}

            <div className="payment-section">
              <div className="hgroup hgroup--inline">
                <h2>Payment Confirmed</h2>
                {!isHarness && (
                  <a href={`https://${homeDomain}/#/billing#upcoming-payments`}>
                    View Payment History
                  </a>
                )}
              </div>

              <div className="mb18 clearfix">
                <PaymentSummary
                  autopay={autopay}
                  showPrint={!isHarness}
                  summary={confirm}
                  className="mb12"
                />

                {enrollSuccess && (
                  <Card className="mb12" severity="warning">
                    <p>
                      {`You'll receive a one-time late fee credit of ${formatCurrency(latefeecredit.lateFee)}
                        on your next bill after your first automatic payment is processed.`}
                    </p>
                  </Card>
                )}
              </div>
              <Promotions {...{ peacockPromo, showFlexPromo, account }} />
              { showXfinityAssistant && <XfinityAssistantBanner intent={XA_XAP_EXITED_INTENT} /> }
            </div>
          </div>
        </PageSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    payment: { confirm },
    bill,
    harness: { isHarness },
    latefeecredit: { latefeecredit, enrollSuccess },
    autopay,
    autopay: { autopaySignupSuccess },
    auth: { macaroon: { accounts = [] } = {} },
    account: { account, account: { accountNumber, serviceAddress = {} } = {} },
  } = state;

  const {
    autoPayEnabled,
    autoPayValid,
  } = (get(bill, 'bill.summary') || {});

  const isDisconnected = getIsDisconnected(account.status);

  return {
    accountNumber,
    confirm,
    bill,
    isHarness,
    latefeecredit,
    autopay,
    enrollSuccess,
    autopaySignupSuccess,
    account,
    showAvoidLateFees: !(autoPayEnabled && autoPayValid) && !isDisconnected,
    showXfinityAssistant: getIsXapExit(account) || account.xap.ltip === 'ELIGIBLE',
    showP2PNote: !getIsXapCurrent(account) && !getIsXapExitInProgress(account),
    selectedAccountAlias: accounts.find(acct => acct.isSelectedAccount)?.alias,
    serviceAddress,
  };
};

const mapDispatchToProps = dispatch => ({
  handleSendChannelTracking: bindActionCreators(sendChannelTracking, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirm);
