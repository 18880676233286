import React from 'react';
import { FormBoolean, LoadingCard } from 'adc-ui-components';
import { Field } from 'formik';
import { connect } from 'react-redux';
import {
  isBlockedInstrument,
  canUseInstrument,
  sortAutoPayFirst,
} from '../../helpers/instruments';

import PaymentInstrument from '../PaymentInstrument';
import BooleanField from '../form-elements/BooleanField';

const SavedPaymentMethod = ({
  autopay,
  instrument,
  index,
  ...more
}) => (
  <div className="card-group__item">
    <div className="card card--action-right-at-768 card--form-control">
      <div className="card__content">
        <FormBoolean
          label={<PaymentInstrument autopay={autopay} instrument={instrument} />}
          labelClass="body2 saved-payment-label"
          inputId={`method_${index}`}
        >
          <Field
            id={`method_${index}`}
            value={instrument.token}
            name="paymentMethodOption"
            component={BooleanField}
            type="radio"
            {...more}
          />
        </FormBoolean>
      </div>
    </div>
  </div>
);

const SavedPaymentMethods = ({
  autopay,
  instruments,
  loading,
  ...more
}) => {
  if (loading) {
    return (
      <div className="card-group__item">
        <LoadingCard className="card-group__item" />
      </div>
    );
  }

  if (!instruments || !instruments.instruments || !instruments.instruments.length) {
    return null;
  }

  const activePaymentMethods = instruments.instruments.filter(instrument => (
    canUseInstrument(instruments.options, instrument)
    && !isBlockedInstrument(instruments, instrument)
  ));

  return sortAutoPayFirst(activePaymentMethods, autopay).map((instrument, index) => (
    <SavedPaymentMethod
      autopay={autopay}
      instrument={instrument}
      index={index}
      key={`savedMethod${instrument.token}`}
      {...more}
    />
  ));
};

const mapStateToProps = state => ({ loading: state.instruments.loading });

export default connect(mapStateToProps)(SavedPaymentMethods);
