import React from 'react';

const CtaLink = ({
  label,
  url,
  behavior = 'external',
  linkClass,
  onClick,
  inline = false,
}) => {
  if (!label || !url) return null;
  const link = (
    <a
      className={linkClass}
      onClick={onClick}
      href={url}
      target={behavior === 'default' ? '_self' : '_blank'}
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
  if (inline) return link;
  return (
    <div className="action__item">
      {link}
    </div>
  );
};

export default CtaLink;
