import {
  GET_PAYMENT_INSTRUMENTS,
  GET_PAYMENT_INSTRUMENTS_SUCCESS,
  GET_PAYMENT_INSTRUMENTS_FAILURE,
  ADD_INSTRUMENT,
  ADD_INSTRUMENT_SUCCESS,
  ADD_INSTRUMENT_FAILURE,
  UPDATE_INSTRUMENT,
  UPDATE_INSTRUMENT_SUCCESS,
  UPDATE_INSTRUMENT_FAILURE,
  DELETE_INSTRUMENT,
  DELETE_INSTRUMENT_SUCCESS,
  DELETE_INSTRUMENT_FAILURE,
} from '../actions/instruments';

import {
  ENABLE_AUTOPAY_SUCCESS,
  DISABLE_AUTOPAY_SUCCESS,
} from '../actions/autopay';

import {
  RECURRING_PAYMENT,
  ONE_TIME_PAYMENT,
  isAutoPayInstrument,
  getAreInstrumentsEqual,
} from '../helpers/instruments';

const INITIAL_STATE = { loaded: false, loading: true, instruments: { instruments: [] } };

export default function instrumentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PAYMENT_INSTRUMENTS:
      return {
        ...state,
        loading: action.showLoading,
      };
    case GET_PAYMENT_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        instruments: {
          ...action.payload,
          instruments: action.payload.instruments.filter(instrument => instrument.type !== 'Cash'),
        },
      };
    case GET_PAYMENT_INSTRUMENTS_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true,
      };
    case DELETE_INSTRUMENT:
    case UPDATE_INSTRUMENT:
    case ADD_INSTRUMENT:
      return {
        ...state,
        submitting: true,
        submitError: false,
      };
    case ADD_INSTRUMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitError: false,
      };
    case UPDATE_INSTRUMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitError: false,
        instruments: {
          ...state.instruments,
          instruments: state.instruments.instruments.map((inst) => {
            if (inst.token === action.payload.instrument.token) {
              return {
                ...inst,
                expirationDate: action.payload.instrument.expirationDate,
              };
            }

            return inst;
          }),
        },
      };
    case DELETE_INSTRUMENT_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitError: false,
        instruments: {
          ...state.instruments,
          instruments: state.instruments.instruments.filter(instrument => (
            instrument.token !== action.payload
          )),
        },
      };
    case ADD_INSTRUMENT_FAILURE:
    case UPDATE_INSTRUMENT_FAILURE:
    case DELETE_INSTRUMENT_FAILURE:
      return {
        ...state,
        submitting: false,
        submitError: true,
      };
    case ENABLE_AUTOPAY_SUCCESS:
      return {
        ...state,
        instruments: {
          ...state.instruments,
          instruments: state.instruments.instruments.map(instrument => ({
            ...instrument,
            paymentMode: (
              action.payload
              && getAreInstrumentsEqual(instrument, action.payload.instrument)
                ? RECURRING_PAYMENT
                : ONE_TIME_PAYMENT
            ),
          })),
        },
      };
    case DISABLE_AUTOPAY_SUCCESS:
      return {
        ...state,
        instruments: {
          ...state.instruments,
          instruments: state.instruments.instruments.map((instrument) => {
            let { paymentMode } = instrument;

            if (isAutoPayInstrument(instrument)) {
              paymentMode = 'OneTimePayment';
            }

            return {
              ...instrument,
              paymentMode,
            };
          }),
        },
      };
    default:
      return state;
  }
}
