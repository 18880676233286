import apiClient from '../middleware/apiClient';
import { COLLECTIONS_OFFER, ENROLL_INSTALLMENT_PLAN, PAYMENT_PLAN_SIGNUP } from '../helpers/apis';
import { flagEnabled } from '../helpers/featureFlags';
import { getIsToday } from '../helpers/date';
import { submitPayment as _submitPayment } from './payment';
import { calculatePlans } from '../helpers/ltip';
import { getPaymentCardType, last4 } from '../helpers/paymentInstrument';
import { formatCurrency } from '../helpers/formatText';
import { dispatchSendTransaction } from './adobe';
import { p2pProps } from '../helpers/p2p';

export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS';
export const GET_COLLECTIONS_FAILURE = 'GET_COLLECTIONS_FAILURE';

function dispatchGetCollections({ pauseConsentId }) {
  const collectionsFetch = !!pauseConsentId && flagEnabled('global.collections2', { defaultValue: true });
  return {
    type: GET_COLLECTIONS,
    payload: collectionsFetch
      ? apiClient.fetch(COLLECTIONS_OFFER(pauseConsentId))
      : Promise.resolve({}),
  };
}

function dispatchGetCollectionsSuccess(collections) {
  return {
    type: GET_COLLECTIONS_SUCCESS,
    payload: collections,
  };
}

function dispatchGetCollectionsFailure(error) {
  return {
    type: GET_COLLECTIONS_FAILURE,
    payload: error,
  };
}

export const getCollections = () => async (dispatch, getState) => {
  const {
    auth: {
      pauseConsentId,
    } = {},
  } = getState();

  try {
    const response = await dispatch(dispatchGetCollections({ pauseConsentId })).payload;
    return dispatch(dispatchGetCollectionsSuccess(response));
  } catch (error) {
    dispatch(dispatchGetCollectionsFailure(error));
    throw error;
  }
};

export const REVIEW_COLLECTIONS = 'REVIEW_COLLECTIONS';

function dispatchReviewCollections(data) {
  return {
    type: REVIEW_COLLECTIONS,
    payload: data,
  };
}

export const reviewPaymentPlan = values => async (dispatch, getState) => {
  const {
    bill: { bill },
    ltip,
    instruments: { instruments: { instruments } = {} } = {},
    auth: { macaroon: { preferredEmail } },
  } = getState();
  const plans = calculatePlans({ bill, ltip });
  const plan = plans.find(({ value }) => value === values.plan);
  const { otherAmount, minimumPayment } = plan;
  const {
    customAmount = '', paymentAmountOption, token, walletId,
  } = values;
  const amount = (() => {
    const option = parseFloat(paymentAmountOption);
    if (isNaN(option) || !otherAmount) return minimumPayment;
    if (paymentAmountOption !== 'custom') return option;
    return parseFloat(customAmount.replace(/^\$/, ''));
  })();
  const {
    date,
    paymentMethodOption,
  } = values;
  const isToday = getIsToday(date);
  const payInfull = isToday && amount >= bill.summary.balanceDue;
  let instrument;
  if (typeof paymentMethodOption === 'object') {
    instrument = paymentMethodOption;
  } else if (paymentMethodOption === 'PaymentCard') {
    const {
      firstName,
      lastName,
      cardNumber,
      cvv,
      expirationMonth,
      expirationYear,
      billingAddressCheckbox,
      line1,
      line2,
      city,
      state,
      zip,
    } = values;
    instrument = {
      type: paymentMethodOption,
      cardType: getPaymentCardType(cardNumber),
      firstName,
      lastName,
      cardNumber,
      cvv,
      expirationMonth,
      expirationYear,
      expirationDate: `${expirationMonth}${expirationYear.substring(2)}`,
      ...(billingAddressCheckbox && {
        line1,
        line2,
        city,
        state,
        zip,
      }),
    };
  } else if (paymentMethodOption === 'Bank') {
    const {
      account: accountType,
      firstName,
      lastName,
      routeNumber,
      bankNumber,
      billingAddressCheckbox,
      line1,
      line2,
      city,
      state,
      zip,
    } = values;
    instrument = {
      type: paymentMethodOption,
      account: accountType,
      routeNumber,
      bankNumber,
      firstName,
      lastName,
      ...(billingAddressCheckbox && {
        line1,
        line2,
        city,
        state,
        zip,
      }),
    };
  } else if (paymentMethodOption === 'Apple Pay') {
    instrument = {
      type: 'ApplePay',
    };
  } else {
    instrument = instruments.find(ins => (
      ins.token === paymentMethodOption
    ));
  }
  return dispatch(dispatchReviewCollections({
    amount,
    payInfull,
    date,
    paymentMethodOption,
    instrument,
    email: preferredEmail,
    isToday,
    plan,
    token,
    walletId,
  }));
};

export const SUBMIT_COLLECTIONS = 'SUBMIT_COLLECTIONS';
export const SUBMIT_COLLECTIONS_SUCCESS = 'SUBMIT_COLLECTIONS_SUCCESS';
export const SUBMIT_COLLECTIONS_FAILURE = 'SUBMIT_COLLECTIONS_FAILURE';

export const ENROLL_USER_INSTALMMENT = 'ENROLL_USER_INSTALMMENT';
export const ENROLL_USER_INSTALMMENT_SUCCESS = 'ENROLL_USER_INSTALMMENT_SUCCESS';
export const ENROLL_USER_INSTALMMENT_FAILURE = 'ENROLL_USER_INSTALMMENT_FAILURE';

const dispatchEnrollUserInstallment = (payload) => {
  const isXap2 = !!(payload.cartId && payload.servicePause);
  const endpoint = isXap2 ? ENROLL_INSTALLMENT_PLAN : PAYMENT_PLAN_SIGNUP;
  return {
    type: ENROLL_USER_INSTALMMENT,
    payload: apiClient.fetch(endpoint, { method: 'POST', body: JSON.stringify(payload) }),
  };
};

const dispatchEnrollUserInstallmentSuccess = payload => ({
  type: ENROLL_USER_INSTALMMENT_SUCCESS,
  payload,
});

const dispatchEnrollUserInstallmentFailure = payload => ({
  type: ENROLL_USER_INSTALMMENT_FAILURE,
  payload,
});

const dispatchSubmitCollections = () => ({
  type: SUBMIT_COLLECTIONS,
});

const dispatchSubmitPaymentSuccess = payload => ({
  type: SUBMIT_COLLECTIONS_SUCCESS,
  payload,
});

const dispatchSubmitPaymentFailure = payload => ({
  type: SUBMIT_COLLECTIONS_FAILURE,
  payload,
});

const displayInstrument = (instrument) => {
  const {
    cardNumber,
    cardType,
    account: accountType,
    bankNumber,
    type,
    last4DigitsCardNumber,
    bankAccountType,
    bankAccountNumber,
  } = instrument;
  if (type === 'token' || type === 'ApplePay') return instrument;
  if (type === 'PaymentCard') {
    return {
      type,
      last4DigitsCardNumber: last4DigitsCardNumber || last4(cardNumber || ''),
      cardType,
    };
  }
  if (type === 'Bank') {
    return {
      type,
      bankAccountType: bankAccountType || accountType,
      bankAccountNumber: last4(bankAccountNumber) || last4(bankNumber || ''),
    };
  }
  return null;
};

const plans = {
  HIGH_SPEED_DATA: '50 Mbps Internet + Unlimited Data',
  VO_IP: '50 Mbps Internet & Voice + Unlimited Data',
};

export const enrollUserInstallmentPlan = () => async (dispatch, getState) => {
  const {
    account: { account },
    bill: { bill, bill: { summary: { pastDueBalanceRemaining } = {} } = {} } = {},
    collections: {
      review,
      review: {
        amount,
        date,
        plan: {
          isLtip,
          noOfPayments,
          installment,
          trackingCode,
          frequency,
        } = {},
      } = {},
      collections: {
        consentCart: {
          cartId,
          consentGroups: [{
            consentItems: [{
              chargeDetails: [{
                monthlyCharges,
              } = {}] = [],
              lineOfService,
            } = {}] = [],
          } = {}] = [],
        } = {},
      } = {},
    } = {},
  } = getState();

  const payInfull = !!(
    (amount && parseFloat(amount) >= pastDueBalanceRemaining)
    && (!date || getIsToday(date))
  );

  const { isP2P, qualifies } = p2pProps({ account, bill, review });
  const p2p = !isLtip && !payInfull && !!isP2P && !!qualifies;

  const payload = {
    payInfull,
    p2p,
    ...(isLtip && !payInfull && !p2p && {
      installmentPlan: {
        numberOfPayments: noOfPayments,
        paymentAmount: installment,
        trackingCode,
        frequency,
      },
    }),
    ...(cartId && {
      cartId,
      servicePause: {
        amount: formatCurrency(monthlyCharges),
        plan: plans[lineOfService],
      },
    }),
  };

  try {
    await dispatch(dispatchEnrollUserInstallment(payload)).payload;

    // Send an SST regarding the mode of plan enrollment
    dispatch(dispatchSendTransaction({
      name: 'self service transaction',
      action: 'self service transaction',
      transaction: isLtip
        ? `billing:${noOfPayments}MonthlyInstallments`
        : 'billing:oneTimePayment',
    }));

    dispatch(dispatchEnrollUserInstallmentSuccess({ enrolled: true }));
  } catch (error) {
    dispatch(dispatchEnrollUserInstallmentFailure(error));
  }
};

export const submitPayment = ({ autopay }) => async (dispatch, getState) => {
  const {
    collections: { review },
  } = getState();
  dispatch(dispatchSubmitCollections());
  let confirm;
  try {
    confirm = await _submitPayment({ autopay })(dispatch, getState);
    await enrollUserInstallmentPlan()(dispatch, getState);
    const { enrolled } = getState().collections;
    dispatch(dispatchSubmitPaymentSuccess({
      amount: confirm.amount,
      date: confirm.date,
      instrument: displayInstrument(review.instrument),
      confirmationNumber: confirm.confirmationNumber,
      authorizationNumber: confirm.authorizationNumber,
      email: confirm.email,
      plan: review.plan,
      isToday: review.isToday,
      enrolled,
      autopay: confirm.autopay,
    }));
  } catch (e) {
    dispatch(dispatchSubmitPaymentFailure(e));
    throw e;
  }
};

export const STORE_FORM_STATE = 'STORE_FORM_STATE';

export const storeFormState = payload => async dispatch => dispatch({
  type: STORE_FORM_STATE,
  payload,
});
