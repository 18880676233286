import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import { LoadingCard, PageSection } from 'adc-ui-components';
import dateformat from 'dateformat';

import { kibanaLog } from '../../helpers/logger';
import { scrollToInvalid, getErrorFromResponse } from '../../helpers/errors';
import { submitPayment } from '../../actions/payment';
import { sendChannelTracking } from '../../actions/channelTracking';
import { reviewPaymentPlan, storeFormState } from '../../actions/collections';
import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { getDefaultInstrumentValue } from '../../helpers/paymentInstrument';
import withCmsContent from '../../helpers/withCmsContent';
import { joinClass } from '../../helpers/component';

import ErrorMessage from '../../components/ErrorMessage';
import SelectPlan from '../../components/Collections2/SelectPlan';
import P2PUnqualified from '../../components/Collections2/P2PUnqualified';
import PaymentPlanAmount from '../../components/form-fields/PaymentPlanAmount';
import PaymentDate from '../../components/form-fields/PaymentDate';
import PaymentMethods from '../../components/form-fields/PaymentMethods';
import PageTitle from '../../components/PageTitle';


import getConfig from '../../config';

import {
  COLLECTIONS_CONFIRMATION,
  COLLECTIONS_REVIEW,
  COLLECTIONS_BANK,
  COLLECTIONS_CARD,
  COLLECTIONS,
  METHODS,
  NEW_PAYMENT,
  PAYMENT_PLAN,
} from '../../helpers/routes';

import { enumeratePlans } from '../../helpers/ltip';
import { getLtipOptions } from '../../actions/ltip';
import useScheduled from '../../hooks/useScheduled';
import { isSameDay, parseDate, today } from '../../helpers/date';
import Card from '../../components/Card';
import { p2pProps } from '../../helpers/p2p';
import PastDueNba from '../../components/nbas/PastDueNba';

const { homeDomain, collectionsBack } = getConfig();

const NBAConsumer = ({
  nba: {
    name,
    description,
  } = {},
}) => (
  <div className="card-group__item">
    <div className="card">
      <div className="card__content">
        <div className="hgroup">
          <h4 className="body2">{name}</h4>
          <p>
            {description}
          </p>
        </div>
      </div>
    </div>
  </div>
);

const PaymentFormik = ({
  values,
  setFieldValue,
  setFieldError,
  setTouched,
  touched,
  softDisconnected,
  minimumPayment,
  plans,
  updateInitialPayment,
  content,
  pauseConsentId,
  p2pPlanCount,
  errorMessage,
  paymentErrorMessage,
  bill,
  autopay,
  applePay,
  instruments,
  submitting,
  displayP2PUnqualifiedModal,
  closeP2PUnqualifiedModal,
  handleStoreFormState,
  refreshQueued,
  refreshing,
}) => {
  const [plan, setPlan] = useState(null);
  useEffect(() => {
    setPlan(values.plan && plans.find(({ value } = {}) => value === values.plan));
  }, [values.plan, plans]);

  // If the user returns to the page with a stored custom amount, make sure the LTIP option
  //  matches it.
  useEffect(() => {
    const customAmount = values.customAmount && parseFloat(values.customAmount.replace(/^\$/, ''));
    if (
      plan && plan.isLtip
      && values.paymentAmountOption === 'custom'
      && customAmount !== plan.minimumPayment
    ) {
      updateInitialPayment(customAmount);
    }
  }, [plan, updateInitialPayment, values.paymentAmountOption, values.customAmount]);

  const updateDate = useCallback((date) => {
    setTimeout(() => {
      setFieldValue('date', dateformat(date, 'm/d/yyyy'));
    }, 0);
  }, [setFieldValue]);

  // Quick form sanity checks
  useEffect(() => {
    const current = parseDate(values.date);
    if (plan && !plan.validDates && !isSameDay(today(), new Date(current))) {
      updateDate(today());
    }
  }, [plan, values.date, updateDate]);

  useEffect(() => {
    if (values.paymentAmountOption === undefined && plan && plan.isLtip) {
      setTimeout(() => {
        setFieldValue('paymentAmountOption', String(minimumPayment));
      }, 0);
    }
  }, [values.paymentAmountOption, plan, setFieldValue, minimumPayment]);

  const handleAmountChange = useCallback(({ meta: { value } }) => {
    if (!value) {
      updateInitialPayment(undefined);
      return;
    }
    const customAmount = parseFloat(value.replace(/^\$/, '')) || 0;
    const customInitialPayment = Math.max(minimumPayment || 0, customAmount) || undefined;
    updateInitialPayment(customInitialPayment);
  }, [updateInitialPayment, minimumPayment]);

  useEffect(() => {
    const { customAmount } = values;
    const { summary: { balanceDue } } = bill;
    const amount = customAmount ? parseFloat(customAmount.replace(/^\$/, '')) : 0;
    // Don't preserve customAmount when switching off LTIP if customAmount _was_ over balanceDue.
    if (plan && !plan.isLtip && amount > balanceDue) {
      setFieldValue('customAmount', '');
      handleAmountChange({ meta: { value: undefined } });
    }
  }, [plan, bill, values, setFieldValue, handleAmountChange]);

  return (
    <Form noValidate>
      <div className="payment-section">
        <PageTitle>{content('ltip.heading')}</PageTitle>
        <p>
          {softDisconnected && pauseConsentId && content('ltip.description.services.disconnected')}
          {!softDisconnected && pauseConsentId && content('ltip.description.services.connected')}
          {softDisconnected && !pauseConsentId && content('ltip.description.noServices.disconnected')}
          {!softDisconnected && !pauseConsentId && content('ltip.description.noServices.connected')}
          {' '}
          {!!p2pPlanCount && content('ltip.description.resolution.schedulable')}
          {!p2pPlanCount && content('ltip.description.resolution.todayOnly')}
        </p>
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {paymentErrorMessage && <ErrorMessage errorMessage={paymentErrorMessage} />}
      <SelectPlan name="plan" plans={plans} />
      {plan && plan.isLtip && (
        <Card severity="warning">{content('ltip.estimate')}</Card>
      )}

      {plan && plan.otherAmount && (
        <>
          <PaymentPlanAmount
            goodFaithPayment={plan.minimumPayment}
            minimumPayment={minimumPayment}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={(name, value) => setTouched({ ...touched, [name]: value })}
            values={values}
            onChange={handleAmountChange}
            heading={(
              <>
                <h2>{content('ltip.amountHeading')}</h2>
              </>
            )}
          />
          {plan && !plan.enabled && (
            <ErrorMessage errorMessage={content('ltip.tooMuchMoney')} />
          )}
        </>
      )}

      {plan && plan.validDates && (
        <PaymentDate
          bill={bill}
          plan={plan}
          values={values}
          setFieldValue={setFieldValue}
          preamble={!!p2pPlanCount && <PastDueNba NBAComponent={NBAConsumer} />}
          keepItShort={p2pPlanCount > 1}
        />
      )}

      <div className="payment-section">
        <div className="hgroup hgroup--inline">
          <h2>{content('paymentMethod.title')}</h2>
          <Link
            to={{
              pathname: METHODS,
              state: { previousPage: COLLECTIONS },
            }}
            onClick={() => handleStoreFormState(values)}
          >
            {content('paymentMethod.ctaText')}
          </Link>
        </div>
        <PaymentMethods
          autopay={autopay}
          applePay={applePay}
          instruments={instruments}
          canStoreInstruments
          bill={bill}
          values={values}
        />
      </div>

      <div className="action action--right">
        <div className="action__item">
          <button
            className={joinClass('button button--primary', submitting && 'is-loading')}
            disabled={submitting}
            type="submit"
          >
            {content('form.submit')}
          </button>
        </div>
        <div className="action__item">
          {pauseConsentId && (
            <a
              className="button"
              href={collectionsBack}
              disabled={submitting}
            >
              {content('form.back')}
            </a>
          )}
          {!pauseConsentId && (
            <a
              className="button"
              href={`https://${homeDomain}/`}
              disabled={submitting || refreshing || refreshQueued}
            >
              {content('form.cancel')}
            </a>
          )}
        </div>
      </div>
      <P2PUnqualified
        bill={bill}
        isOpen={displayP2PUnqualifiedModal}
        closeModal={closeP2PUnqualifiedModal}
      />
    </Form>
  );
};

const PaymentForm = ({
  autopay,
  bill,
  bill: { summary: { softDisconnected } = {} },
  instruments,
  initialValues,
  history,
  handleReviewPaymentPlan,
  applePay,
  loading,
  handleSubmitPayment,
  errorMessage,
  paymentFormData,
  handleSetPaymentFormData,
  handleSetError,
  handleSetSubmit,
  handleSetSuccess,
  handleStoreFormState,
  minimumPayment,
  submitting,
  cantUseCollections,
  xapEligible,
  content,
  handleGetLtipOptions,
  plans,
  pauseConsentId,
  paymentErrorMessage,
  refreshing,
}) => {
  const [displayP2PUnqualifiedModal, setDisplayP2PUnqualifiedModel] = useState(false);
  const closeP2PUnqualifiedModal = () => {
    setDisplayP2PUnqualifiedModel(false);
  };
  const onFormSubmit = (values, { setSubmitting }) => {
    // Clear the on-nav formstate.
    handleStoreFormState(undefined);
    const {
      paymentMethodOption,
    } = values;
    const plan = values.plan && plans.find(({ value } = {}) => value === values.plan);
    if (plan.otherAmount && !plan.enabled) {
      return false;
    }
    if (plan.isP2P) {
      const { qualifies } = p2pProps({
        bill,
        values,
        plan,
      });
      if (!qualifies) {
        setDisplayP2PUnqualifiedModel(true);
        return false;
      }
    }
    kibanaLog('payment_form_submit', {
      form: 'Collections',
    });

    handleSetSubmit();
    // store payment data in the state and submit in addinstrument form
    // or restore initialValues from state if the user comes back
    const adjusted = {
      ...values,
      ...(values.paymentAmountOption !== 'custom' && { customAmount: undefined }),
    };
    handleSetPaymentFormData(adjusted);

    if (paymentMethodOption === 'Bank') {
      return history.push(COLLECTIONS_BANK);
    }

    if (paymentMethodOption === 'PaymentCard') {
      return history.push(COLLECTIONS_CARD);
    }

    if (paymentMethodOption === 'Apple Pay') {
      return handleReviewPaymentPlan(values).then(() => (
        handleSubmitPayment(false).then(() => {
          handleSetSuccess();
          history.push(COLLECTIONS_CONFIRMATION);
        }).catch((applePayServerErr) => {
          setSubmitting(false);
          scrollToInvalid();

          if (applePayServerErr.type !== 'cancel') {
            const applePayServerError = getErrorFromResponse(applePayServerErr);

            if (applePayServerError.message) {
              return handleSetError({ text: applePayServerError.message });
            }

            return handleSetError({ showModal: true });
          }
          return false;
        })
      ));
    }

    return handleReviewPaymentPlan(adjusted).then(() => history.push(COLLECTIONS_REVIEW));
  };

  const [refreshQueued, updateInitialPayment] = useScheduled(handleGetLtipOptions, {
    timeout: 500, callOnUnmount: false,
  });

  if (cantUseCollections) {
    if (xapEligible) {
      return <Redirect to={PAYMENT_PLAN} />;
    }
    return <Redirect to={NEW_PAYMENT} />;
  }

  if (loading) {
    return <PageSection><LoadingCard /></PageSection>;
  }
  const p2pPlanCount = plans.filter(({ isP2P }) => isP2P).length;
  return (
    <PageSection>
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          ...initialValues,
          ...paymentFormData,
        }}
      >
        {formikInfo => (
          <PaymentFormik
            {...formikInfo}
            {...{
              softDisconnected,
              minimumPayment,
              plans,
              updateInitialPayment,
              content,
              pauseConsentId,
              p2pPlanCount,
              errorMessage,
              paymentErrorMessage,
              bill,
              autopay,
              applePay,
              instruments,
              submitting,
              displayP2PUnqualifiedModal,
              closeP2PUnqualifiedModal,
              handleStoreFormState,
              refreshQueued,
              refreshing,
            }}
          />
        )}
      </Formik>
    </PageSection>
  );
};

const mapStateToProps = ({
  auth: {
    pauseConsentId,
  } = {},
  autopay: {
    autopay,
    loading: autopayLoading,
  },
  bill: { bill = {}, loading: billLoading },
  applePay: { applePay },
  instruments: { instruments },
  userMessages: {
    error: errorMessage,
    showModal,
  },
  ltip: {
    loading: ltipLoading,
    refreshing,
    options: ltipOptions,
    minimum: ltipMinimum,
    minimum: { initialPaymentAmount: minimumPayment } = {},
  } = {},
  payment: {
    error: {
      message: paymentErrorMessage,
    } = {},
  } = {},
  account: {
    loading: accountLoading,
    account: {
      xap: {
        servicePause,
        ltip,
        xapExit,
      } = {},
    } = {},
  } = {},
  collections: { formState = {} },
}, { content }) => {
  const loading = autopayLoading || billLoading || ltipLoading || accountLoading;
  const hereForServicePause = servicePause === 'ELIGIBLE' && pauseConsentId;

  const plans = hereForServicePause && !loading ? enumeratePlans({
    bill,
    ltip: {
      options: ltipOptions || [],
      minimum: ltipMinimum || [],
    },
    content: content('plan'),
  }) : [];

  const {
    initialPaymentAmount: goodFaithPayment,
  } = ltipOptions;

  const initialValues = {
    plan: plans && plans.length && plans[0].value,
    date: dateformat(new Date(), 'm/d/yyyy'),
    paymentMethodOption: getDefaultInstrumentValue(instruments, autopay),
    ...formState,
  };

  return {
    pauseConsentId,
    bill,
    autopay,
    goodFaithPayment,
    minimumPayment,
    instruments,
    applePay,
    refreshing,
    loading,
    errorMessage: !showModal && errorMessage,
    paymentErrorMessage,
    // User is eligible for XAP 1.0 flow.
    xapEligible: ltip === 'ELIGIBLE' || xapExit === 'NOT_STARTED',
    initialValues,
    plans,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleReviewPaymentPlan: reviewPaymentPlan,
  handleSubmitPayment: submitPayment,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
  handleSendChannelTracking: sendChannelTracking,
  handleGetLtipOptions: getLtipOptions,
  handleStoreFormState: storeFormState,
}, dispatch);

export default withCmsContent('content', {
  'ltip-payment-plan': 'Form',
  'collections-2': 'Form',
})(connect(mapStateToProps, mapDispatchToProps)(PaymentForm));
